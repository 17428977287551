/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import { useVimCommunication } from '@getvim/vim-app-infra';
import { Encounter, Patient, WidgetIncomingEventName } from '../types';

export const useAppEventsHandler = (): {
  patient: Patient | undefined;
  encounter: Encounter | undefined;
} => {
  const [patient, setPatient] = useState<Patient>();
  const [encounter, setEncounter] = useState<Encounter>();
  const { vimCommunication } = useVimCommunication();

  useEffect(() => {
    vimCommunication?.listenToEvent(
      WidgetIncomingEventName.PatientInContext,
      ({ data }: { data: any }) => {
        setPatient(data);
      },
    );
    vimCommunication?.listenToEvent(
      WidgetIncomingEventName.EncounterStart,
      ({ data }: { data: any }) => {
        setEncounter(data);
      },
    );
  }, [vimCommunication]);

  useEffect(() => {
    vimCommunication?.listenToEvent(WidgetIncomingEventName.PatientOutOfContext, () => {
      setPatient(undefined);
    });
    vimCommunication?.listenToEvent(WidgetIncomingEventName.EncounterExit, () => {
      setEncounter(undefined);
    });
  }, [vimCommunication]);

  return { patient, encounter };
};
