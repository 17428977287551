import { gql } from '@apollo/client';
import { gqlClient } from '../gql-client';
import { LaunchUrlRequest } from '../../types';

import { kahunDateFormatter } from '../../utils/formatter';

export const getLaunchUrl = async ({
  firstName,
  lastName,
  dateOfBirth,
  zipCode,
}: LaunchUrlRequest): Promise<{ url: string } | undefined> => {
  try {
    const { data } = await gqlClient.query({
      query: getLaunchUrlQuery,
      variables: {
        input: {
          firstName,
          lastName,
          dateOfBirth: kahunDateFormatter(dateOfBirth!),
          zipCode,
        },
      },
      fetchPolicy: 'no-cache',
    });

    return {
      url: data.getLaunchUrl.url,
    };
  } catch (error) {
    console.log('Failed to fetch query', { error });
    return undefined;
  }
};

const getLaunchUrlQuery = gql`
  query GetLaunchUrl($input: GetLaunchUrlInput!) {
    getLaunchUrl(input: $input) {
      url
    }
  }
`;
