import React, { useEffect, useState, useCallback } from 'react';
import { Loader, Toast } from '@getvim/atomic-ui';

import {
  useAppEventsHandler,
  useWriteBack,
  useModifyAppViewState,
  useNotifications,
} from '../../hooks';
import { EhrWritebackDiagnosis } from '../../types';
import { apiClient } from '../../api';
import './index.less';

const KAHUN_SUBMIT_EVENT = 'kahun-submit';

const initialState = {
  url: undefined,
  resultsError: false,
  isLoading: false,
  patientId: null,
};
const { ToastContainer, Slide } = Toast;

export const Widget: React.FC = () => {
  const { encounter, patient } = useAppEventsHandler();
  const { updateEncounter } = useWriteBack();
  const [handleModifyAppView] = useModifyAppViewState();
  const notifications = useNotifications();

  const [launchUrl, setLaunchUrl] = useState<{
    url: string | undefined;
    resultsError: boolean;
    isLoading: boolean;
    patientId: any;
  }>(initialState);
  const [encounterToPatient, setEncounterToPatient] = useState<any>(null);

  const writeBackDiagnosis = useCallback(async (message) => {
    const { data } = message || {};

    if (data.message === KAHUN_SUBMIT_EVENT) {
      const encounterData: EhrWritebackDiagnosis[] = [];
      const {
        data: { codes },
      } = data;

      codes.forEach(({ name, code }) => {
        encounterData.push({ code, description: name });
      });

      if (encounterData.length > 0) {
        const success = await updateEncounter(encounterData);

        notifications.updateEncounterWriteback(success);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', writeBackDiagnosis);
    return () => window.removeEventListener('message', writeBackDiagnosis);
  }, [writeBackDiagnosis]);

  const getWidgetLaunchUrl = async () => {
    const {
      demographics: { address, dateOfBirth, firstName, lastName },
    } = patient!;

    const payload = {
      zipCode: address.zipCode!,
      dateOfBirth,
      firstName,
      lastName,
    };

    const response = await apiClient.getLaunchUrl(payload);

    setLaunchUrl({
      url: response?.url,
      isLoading: false,
      resultsError: !response,
      patientId: patient?.patientId,
    });
  };

  useEffect(() => {
    if (encounterToPatient) {
      getWidgetLaunchUrl();
    }
  }, [encounterToPatient]);

  useEffect(() => {
    // if the encounterId doesn't exist, do nothing
    if (!encounter?.encounterId) return;

    // if encounterToPatient is null, save first pair
    if (!encounterToPatient) {
      setEncounterToPatient({
        [encounter.encounterId]: { ...encounter.patient, currentEncounterId: true },
      });
      return;
    }

    const encounterAlreadyMapped = encounter.encounterId in encounterToPatient;

    if (!encounterAlreadyMapped || !encounterToPatient[encounter.encounterId].currentEncounterId) {
      setEncounterToPatient((prev) => {
        return {
          ...Object.fromEntries(
            Object.entries(prev).map(([key, value]) => {
              return [key, { ...(value as any), currentEncounterId: false }];
            }),
          ),
          [encounter.encounterId]: { ...encounter.patient, currentEncounterId: true },
        };
      });
      return;
    }
  }, [encounter]);

  /**
   * Modify app view for encounterStart/encounterExit events
   */
  useEffect(() => {
    if (!encounter || !launchUrl.url) {
      handleModifyAppView({
        enable: false,
      });
    }

    if (
      encounter &&
      encounterToPatient &&
      encounterToPatient[encounter.encounterId]?.currentEncounterId &&
      launchUrl.url
    ) {
      handleModifyAppView({
        enable: true,
      });
    }
  }, [encounter, encounterToPatient, handleModifyAppView, launchUrl.url]);

  const { url, resultsError, isLoading } = launchUrl;

  const onRetry = () => getWidgetLaunchUrl();

  return (
    <>
      <div className="kahun-app-container">
        <div className="frame-wrapper">
          {isLoading && <Loader type="dots" className="kahun-app-container__loader" />}
          {resultsError && <ErrorLoadIframe onRetry={onRetry} />}
          {url && <iframe title="kahun-widget" className="frame" src={url} />}
        </div>
      </div>
      <ToastContainer
        className=""
        position="bottom-right"
        hideProgressBar
        autoClose={3000}
        pauseOnFocusLoss={false}
        transition={Slide}
      />
    </>
  );
};

const ErrorLoadIframe: React.FC<any> = ({ onRetry }: any) => (
  <div className="error-load-iframe">
    <div className="error-load-iframe-title">Something went wrong :(</div>
    <div className="error-load-iframe-text">Please try again</div>
    <img
      className="error-load-iframe-img"
      src="https://static.getvim.com/img/errorPageImg.png"
      alt="loading logo"
    />
    <button type="button" className="error-load-iframe-button" onClick={onRetry}>
      Retry
    </button>
  </div>
);
