import { useCallback, useEffect, useState } from 'react';
import { Toast } from '@getvim/atomic-ui';
import { NotificationMessageMapping } from '../types';

interface UseNotificationReturnValue {
  updateEncounterWriteback: (success: boolean) => void;
}

const { createToast, ToastTypes } = Toast;

export const useNotifications = (): UseNotificationReturnValue => {
  const [notification, setNotification] = useState<object | null>(null);

  useEffect(() => {
    if (notification) {
      createToast(notification as any);

      setNotification(null);
    }
  }, [notification, setNotification]);

  const updateEncounterWriteback = useCallback(
    (success: boolean) => {
      let notification;

      if (success) {
        notification = {
          title: 'All done!',
          message: NotificationMessageMapping.UPDATE_ENCOUNTER_WRITEBACK_SUCCESS,
          type: ToastTypes.SUCCESS,
          html: true,
        };
      } else {
        notification = {
          title: 'Oops',
          message: NotificationMessageMapping.UPDATE_ENCOUNTER_WRITEBACK_FAIL,
          type: ToastTypes.ERROR,
          html: true,
        };
      }
      setNotification(notification);
    },
    [setNotification],
  );

  return { updateEncounterWriteback };
};
