export enum WidgetIncomingEventName {
  PatientInContext = 'patientInContext',
  PatientOutOfContext = 'patientOutOfContext',
  EncounterStart = 'encounterStart',
  EncounterExit = 'encounterExit',
}

export interface LaunchUrlRequest {
  patientId?: string;
  firstName: string;
  lastName: string;
  dateOfBirth?: string;
  zipCode: string;
}

export interface Address {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}

export interface PatientDemographics {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  address: Address;
}

export interface MemberEligibility {
  plan: string;
  groupNumber: string;
}

export interface Insurance {
  insurer: string;
  memberId: string;
}

export interface Patient {
  demographics: PatientDemographics;
  patientId: string;
  insurance?: Insurance;
  eligibility?: MemberEligibility;
}

interface ProviderDemographics {
  firstName: string;
  lastName: string;
  middleName?: string;
}

interface Provider {
  providerEhrId: string;
  npi?: string;
  demographics: ProviderDemographics;
  specialty?: string;
}

export interface Encounter {
  encounterDate: string;
  encounterId: string;
  provider: Provider;
  patient: Patient;
}

export interface EhrWritebackDiagnosis {
  code: string;
  description: string;
  note?: string;
}

export enum RuntimeOutgoingEvents {
  WriteBack = 'vim-connect-write-back',
}

export enum NotificationMessageMapping {
  UPDATE_ENCOUNTER_WRITEBACK_SUCCESS = 'Chart was updated',
  UPDATE_ENCOUNTER_WRITEBACK_FAIL = 'Failed to updated chart',
}
